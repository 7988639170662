.main-nav{

  
    background-color: rgb(255, 255, 255);
    padding-top: 20px;
    position:relative ;
    width: 100%;
   
    

  
} 
 
 .main-nav .fluid{
    
    height: 150px;
    width:200px;
    
    
}

.fluid-2{

    height: 100px;
    width:190px;
    position: absolute;
    top: 55px;
    left: 200px;
    
}


.nav1 .nav-links {
    list-style-type: none;
    display: flex;
    justify-content: flex-end; 
    margin: 0;
    width:500px;
    position: absolute;
    right: 40px;
    top: 75px;
}

.nav1 .nav-links li {
    position: relative; /* Needed for the absolute positioning of the underline */
    margin: 0 15px; /* Add space between links */
}

.nav1 .nav-links li a {
    text-decoration: none; /* Removes underline from links */
    color: #333; /* Default link color */
    padding: px 0; /* Padding for better click area */
    position: relative; /* Position for the pseudo-element */
}

.nav1 .nav-links li a::after {
    content: ''; /* Required for the pseudo-element */
    position: absolute; /* Positioning relative to the link */
    left: 0; /* Start from the left */
    bottom: 0; /* Position at the bottom of the link */
    width: 100%; /* Full width of the link */
    height: 2px; /* Height of the underline */
    background-color: #007BFF; /* Underline color */
    transform: scaleX(0); /* Start with no width */
    transition: transform 0.3s ease-in-out; /* Animation for scaling */
}

.nav1 .nav-links li a:hover::after {
    transform: scaleX(1); /* Scale to full width on hover */
}

.nav1 .nav-links li {
   
    padding-left: 40px;
    
}

.nav1 .nav-link {
    font-size: 22px;
    color: black;
    font-weight: bold;
    text-transform: capitalize;
    text-decoration: none; 
    transition: 3s linear ease-in-out;
    
}

.nav1 .nav-link:hover {
  scale: 1.1;
  color: rgb(32, 32, 33);
}

 .button-nav img{
 
    height: 50px;
    width: 80px;
    display: none;
    position: absolute;
    right: 0;
    top: 80px;

}


@media screen and (max-width: 968px) {
   

    .nav1 {
        position: relative;
       
    }
    
    
    .nav1 .nav-link {
        opacity: 0;
        transform: translateX(50px); 
        transition: opacity 0.7s ease, transform 0.7s ease; 
    }
    
    .nav1 .nav-links {
        visibility: hidden;
        position: absolute;
        width: 170px;
        height: 775px;
        background-color: rgb(248, 246, 246);
        border: 2px solid #beb8c390;
        border-radius: 8px;
        top: -170px;
        bottom: 10px;
        right: 0;
        margin: 0;
        padding: 0;
        padding-top: 160px;
        box-shadow: 3px 3px 59px 35px #00000034;
        z-index: 1;
        opacity: 0; 
        transition: right 0.7s ease, opacity 0.7s ease, visibility 0s 0.7s; 
        display: block;
        overflow: hidden;
       
    }
    .nav1 .nav-links.active {
        visibility: visible;
        right: -18px; 
        opacity: 1; 
        transition: right 0.7s ease, opacity 0.7s ease; 
    }
    
    
    .nav1 .nav-links.active .nav-link {
        opacity: 1; 
        transform: translateX(0);
    }
    
    .nav-links .nav-link {
        margin-bottom: 30px;
    }
    
    .button-nav img {
        display: block;
        justify-content: flex-end;
        overflow: hidden;
        width: -200%;
        
    }
    
    .button-nav img{
 
        position: absolute;
        right: -px;
        top: 50px;
        z-index: 11;
    
    }

    
}

@media screen and (min-width:320px) and (max-width:480px){

    .main-nav .fluid{
    
        height: 120px;
        width:150px;
        
        
        
    }

    
    .fluid-2{
    
       display: none;
        
    }

    .button-nav img{
 
        position: absolute;
        right: -2px;
        top: 50px;
        z-index: 11;
    
    }

    .nav1 .nav-links {

        visibility: hidden;
        position: absolute;
        width: 190px;
        height: 835px;
        background-color: rgb(248, 246, 246);
        border: 2px solid #beb8c390;
        border-radius: 8px;
        top: -170px;
        bottom: 10px;
        right: 0px;
        margin: 0;
        padding: 0;
        padding-top: 160px;
        box-shadow: 3px 3px 59px 35px #00000034;
        z-index: 1;
        opacity: 0; 
        transition: right 0.7s ease, opacity 0.7s ease, visibility 0s 0.7s; 
        display: block;
        overflow: hidden;
        
        
    }

    .nav1 .nav-links.active {
        visibility: visible;
        right: -18px; 
        opacity: 1; 
        transition: right 0.7s ease, opacity 0.7s ease; 
    }
    
    .nav1 .nav-links.active .nav-link {
        opacity: 1; 
        transform: translateX(0);
    }
    

    



}