.contact-section {
  display: flex;
  flex-direction: column; /* Column layout for mobile screens */
  padding: 60px 20px;
  gap: 40px; 
  background-color: rgb(255, 255, 255);
}

@media (min-width: 768px) {
  .contact-section {
      flex-direction: row; /* Row layout for tablet and above */
      justify-content: space-between;
  }
}

/* Contact Info */
.contact-info {
  flex: 1;
  padding-right: 20px;
}

.contact-info h1 {
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-bottom: -15px;
  margin-left: 50px;
}

.contact-info img {
  width: 30px;
  height: 30px;
  position: absolute;
 
}

.contact-info h2 {
  color: #000000;
  font-size: 32px;
  margin-left: 50px;
}

.contact-info p {
  margin: 20px 0;
  color: #000000;
  font-size: 18px;
  margin-left: 50px;
  text-align: left;
  width: 90%; /* Adjust width for responsiveness */
}

.con1 h3 {
  margin: 30px 0;
  color: #000000;
  font-size: 18px;
  margin-left: 50px;
  text-align: left;
  width: 90%; /* Adjust width for responsiveness */
}

.add, .email, .phone, .hours {
  color: #000000;
  font-size: 24px; /* Reduce font-size for smaller screens */
  font-weight: bold;
  margin-top: 20px;
}

/* Contact Form */
.contact-form {
  flex: 1;
  padding-left: 20px;
}

.contact-form h2 {
  color: #000000;
  font-size: 28px; /* Adjust for responsiveness */
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form label {
  color: #000000;
  font-size: 16px; /* Adjust for smaller screens */
  font-weight: bold;
}

/* General Contact Form Fields */
.contact-form input, .contact-form textarea {
  width: 90%; /* Make full width for mobile */
  padding: 10px; /* Reduce padding for smaller screens */
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  resize: none;
}

.contact-form input, .contact-form textarea::placeholder {
  font-weight: bolder;
}

.contact-form .first-name input {
  width: 90%; /* Adjust width for mobile */
}

/* Aligning First and Last Name in a Single Row */
.contact-form .name-fields {
  display: flex;
  flex-direction: column; /* Stack inputs for mobile */
  gap: 15px;
}

@media (min-width: 768px) {
  .contact-form .name-fields {
      flex-direction: row; /* Row layout for tablet and above */
      gap: 5%;
  }
}

.contact-form textarea {
  height: 120px;
}

.contact-form button {
  height: 50px;
  width: 50%; /* Full width on mobile */
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin: 8px auto;
  margin-right:195px;



  
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .contact-section {
      padding: 60px 20px;
  }

  .contact-info {
      padding-right: 40px;
  }

  .contact-info p, .con1 h3 {
      width: 60%; /* More text space on larger screens */
  }

  .contact-form button {
      width: 50%; /* Reduced button width on larger screens */
  }
}

@media (min-width: 1024px) {
  .contact-info p, .con1 h3 {
      font-size: 18px; /* Increase font size for larger screens */
  }
}