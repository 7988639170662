

  *{

    font-family: roboto;
    margin: 0;
    padding: 0;
    width: 100%;
    ::-webkit-scrollbar{

      display: none;
    }







  }
