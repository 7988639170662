.about-1{

    width:100%;
    height: 150px;
    background: #C8DDFD;
    


}

.about-2{

    display: flex;
    justify-content: center;
    height: auto;
    padding-top: 40px;
    padding-bottom: 50px;
    width: 91%;
    position: relative;
    bottom: 60px;
    left: 62px;
    right: 62px;
    box-shadow: 0px 4px 4px 0px #00000040;
    background: #FFFFFF;
    padding-right: 10px;
    padding-left: 50px;
    


}

.body{

    width: auto;
    height: auto;
    border: 2px 0px 0px 0px;
    background: #FFFFFF;
    border: 2px solid #00000080;
    box-shadow: 0px 4px 4px 0px #00000040;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 50px;
    padding-bottom: 10px;
    position: relative;
   left: 3%;
   right: 3%;
   
 



}

.body h1{

    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.2px;
    text-align: center;
    color: #333333;



}

.body strong{

    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 28.2px;
    text-align: center;
    color: #58BFD5;


}

.body p{

    font-family: Inter;
    font-size: calc(1em - 2px); 
    font-weight: 700;
    line-height: 28.2px;
    text-align: center;
    color: #333333;


}

.body .image-div{

   
   margin-bottom: 30px;
   



    
}
.body img{

    width: 200px;
    height: 231.41px;
    margin-bottom: 20px;
    margin-top: 30px;
    border: 2px solid #00000080;
    box-shadow: 0px 4px 4px 0px #00000040;
   
   

}

@media screen and (min-width:320px) and (max-width:480px){

    .main-about{

        overflow: hidden;
      
       
    }

    .about-1{

        width: 100%;
        height: 80px;
        z-index: -1;
        position: relative;
        background: #C8DDFD;
        
        
    
        
        
    
    
    }

    .about-2{

        inset:0;
        padding-left: 30px;
        padding-right: 30px;
        margin-left: 20px;
        position: absolute;
        overflow: scroll;
        height: 595px;
        margin-top: 140px;
        padding-top: 80px;
        background:transparent;
        box-shadow:none;
       
        
       
        

    }

    .body{

        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        padding: 0;
        width: 50%;
        
       
       
       

    }

    .body img{

        margin-bottom: -10px;
        margin-top: 25px;
        width: 190px;
        height: 231.41px;
    }



}

@media screen and (min-width:480px) and (max-width:768px){

    .about-2{

        inset:0;
        width: 90%;
        margin-left: 20px;
        box-shadow: 0px 4px 4px 0px #00000040;
        background: #FFFFFF;
        padding-right: 20px;
        padding-left: 60px; 
        margin-top: -50px;

    }

    
.body{

    margin-left: 10px;
    margin-right: 30px;
    margin-bottom: 50px;
    padding-bottom: 10px;
 
   
 



}

.about-1{

  position: sticky;
  top: 10px;
    

    
    


}




}

@media screen and (min-width:768px) and (max-width:1024px) {

    .about-2{

        inset:0;
        width: 90%;
        margin-left: 4%;
        box-shadow: 0px 4px 4px 0px #00000040;
        background: #FFFFFF;
        padding-right: 1px;
        padding-left: 1px; 
        margin-top: -50px;
     

    }

    .body{

        margin-left: 70px;
        margin-right: 0;
        margin-bottom: 50px;
     
       
     
    
    
    
    }

    .body img{
        width: 190px;
        height: 213.41px;
       
    }

    .body h1{

        font-family: Inter;
        font-size: 20px;
        font-weight: 700;
        line-height: 28.2px;
        text-align: center;
        color: #333333;
    
    
    
    }



}

@media screen and (min-width:1024px) and (max-width:1200px) {

    .about-2{

        inset:0;
        width: 90%;
        margin-left: 4%;
        box-shadow: 0px 4px 4px 0px #00000040;
        background: #FFFFFF;
        padding-right: 1px;
        padding-left: 1px; 
        margin-top: -50px;
       
     

    }

    .body{

        margin-left: 70px;
        margin-right: 0;
        margin-bottom: 50px;
     
       
     
    
    
    
    }

    .body img{
        width: 190px;
        height: 213.41px;
       
    }

    .body h1{

        font-family: Inter;
        font-size: 20px;
        font-weight: 700;
        line-height: 28.2px;
        text-align: center;
        color: #333333;
    
    
    
    }



}

