.page8 {
  height: auto;
  background-color: var(--background-color);
  color: var(--text-color);
  padding-bottom: 70px;
  position: relative;
  overflow: hidden;
}

.page8 h1, h3, h4 {
  text-decoration: underline;
  text-transform: capitalize;
}

.page8 .logo-term {
  transform: scale(0.45);
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  margin-left: -100px;
}

.page8 h5 {
  display: flex;
  margin-left: 200px;
  font-weight: bolder;
}

.page8 h6 {
  float: left;
}

.upper {
  margin-left: 19vw;
  margin-bottom: -20px;
  width: 55vw;
}

.upper p {
  font-size: 18px;
  font-weight: 600;
  display: flex;
}

.policy-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  border-radius: 8px;
}

.policy-container img {
  position: absolute;
  margin-left: -390px;
  margin-top: -200px;
  opacity: 0.6;
  transform: scale(0.5);
}

.policy-title {
  font-size: 24px;
  margin-bottom: 15px;
  text-align: center;
  color: #333;
}

.policy-list {
  list-style-type: decimal;
  padding-left: 20px;
}

.policy-list li {
  margin-bottom: 10px;
  line-height: 1.6;
  font-size: 20px;
}

.policy-list strong {
  color: #333;
  font-size: 18px;
}

.policy-list ul {
  list-style-type: circle;
  margin-left: 20px;
}

.policy-list ul li {
  margin-bottom: 5px;
  line-height: 1.4;
}

.policy-list ul li {
  width: 750px;
}

@media screen and (min-width:768px) and (max-width:1200px){

  .page8 h1{
    font-size: 32px;
   }
   .page8  p{
    font-size: 20px;
    text-decoration: double;
    width:100%;
    line-height: 1.6;
   }
  
   .policy-list li{
    font-size: 20px;
    width:100%;
    padding-bottom: 10px;
    
    
   }
   .policy-list ul li{
    font-size: 17px;
    width:100%;
    line-height: 1.8;
    text-align: left;
   }
   .policy-container img {
    position: absolute;
    margin-left: -200px;
    margin-top: -50px;
    opacity: 0.6;
    transform: scale(0.5);
    width: 1000px;
    height: 800px;
    justify-content: center; 
   }

}

@media screen and (min-width:480px) and (max-width:768px){

  .page8 h1{
    font-size: 32px;
   }
   .page8  p{
    font-size: 20px;
    text-decoration: double;
    width:100%;
    line-height: 1.6;
   }
  
   .policy-list li{
    font-size: 20px;
    width:100%;
    padding-bottom: 10px;
    
   }
   .policy-list ul li{
    font-size: 18px;
    width:100%;
    line-height: 1.8;
    text-align: left;
   }
   .policy-container img {
    position: absolute;
    right: 80px;
    left: 250px;
    opacity: 0.6;
    transform: scale(0.5);
    width: 800px;
    height: 700px;
    justify-content: center; 
   }
  


}

@media screen and (min-width:320px) and (max-width:480px){
  
 .page8 h1{
  font-size: 32px;
 }
 .page8  p{
  font-size: 14px;
  text-decoration: double;
  width:290px;
  line-height: 1.6;
 }

 .policy-list li{
  font-size: 18px;
  width:290px;
  padding-bottom: 10px;
  
 }
 .policy-list ul li{
  font-size: 14px;
  width:290px;
  line-height: 1.8;
  text-align: left;
 }
 .policy-container img {
  position: absolute;
  margin-left: -40px;
  margin-top: -50px;
  opacity: 0.6;
  transform: scale(0.5);
  width: 500px;
  height: 450px;
  justify-content: center; 
 }

}




