.sec-1{

    background-color: rgb(255, 255, 255);
    overflow: hidden;
   
    
}

.sec-1 img{
    height: 90%;
    width: 90%;

}


.sec-1 p{

    color: rgba(0, 0, 0, 0.756);
    font-weight: bolder;
    font-size: 20px;
    width: 90%;
}

.sec-1 h1{

    font-size: 48px;
    font-weight: bolder;
    text-transform: uppercase;
}

.sec-1 .cont{

    margin-top: 180px;
    position: relative;
    left: 50px;
}

@media screen and (min-width:320px) and (max-width:480px) {

    .sec-1{
        overflow: hidden; 
        width: 100%;
    }

    .sec-1 .cont{

        margin-top: 30px;
        position: relative;
        left: 10px;
    }

    
.sec-1 h1{

    width: 100%;
    font-family: Inter;
    font-size: 23px;
    font-weight: bolder;
    text-align: left;


}

.sec-1 p{

    font-family: Inter;
    font-size: 15px;
    font-weight: bolder;
    text-align: left;
    width:180%;
    
}

.sec-1 img{

    position: relative;
    height: 350px;
    width: 350px;
    top: 250px;
    right: 205px;
    margin-bottom: 260px;

}

    
}

@media screen and (min-width:480px) and (max-width:768px) {

    .sec-1{
        overflow: hidden; 
        width: 100%;
    
    }
    .sec-1 .cont{
        margin-top: 30px;
        position: relative;
        left: 20px;
    }
    
.sec-1 h1{
    width: 100%;
    font-family: Inter;
    font-size: 26px;
    font-weight: bolder;
    text-align: left;
}
.sec-1 p{
    font-family: Inter;
    font-size: 20px;
    font-weight: bolder;
    text-align: left;
    width:180%;
    
}
.sec-1 img{
    position:relative;
    height: 400px;
    width: 400px;
    top: 250px;
    right: 290px;
    margin-bottom: 260px;
    
}
    
}

@media screen and (min-width:768px) and (max-width:1024px) 
{
    .sec-1 img{
        height: 100%;
        width: 100%;
    
    }
    
    
    .sec-1 p{
    
        color: rgba(0, 0, 0, 0.756);
        font-weight: bolder;
        font-size: 20px;
        width: 90%;
    }
    
    .sec-1 h1{
    
        font-size: 37px;
        font-weight: bolder;
        text-transform: uppercase;
    }
    
    .sec-1 .cont{
    
        margin-top: 180px;
        position: relative;
        left: 50px;
    }
    
}
@media screen and (min-width:1024px) and (max-width:1200px) 
{
 
    .sec-1 img{
        height: 430px;
        width: 430px;
    
    }
    
    
    .sec-1 p{
    
        color: rgba(0, 0, 0, 0.756);
        font-weight: bolder;
        font-size: 20px;
        width: 90%;
    }
    
    .sec-1 h1{
    
        font-size: 37px;
        font-weight: bolder;
        text-transform: uppercase;
    }
    
    .sec-1 .cont{
  

        margin-top: 180px;
        position: relative;
        left: 50px;
    }
}

.div-2{

    background-color: rgb(255, 255, 255);
    overflow:hidden ;
    padding-bottom:20px;
   
}



.div-2 h1{

    font-size: 40px;
    font-weight: bolder;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 100px;
}

.div-2 .box img{

    height: 70px;
    width: 70px;
    margin-top: 20px;
    margin-bottom: 10px;
   

}

.div-2 h2{

    font-family: OpenSans;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    text-transform: capitalize;
}
.div-2 .card-container {
    display: flex;
    justify-content: center;   
}

.div-2 .box{
    width: 285px;
    height: 246px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: white;
    transform-style: preserve-3d;
    margin-right: -55px;
    transform: rotateY(-50deg) rotateZ(15deg) rotateX(-10deg);
    transition:  0.350s  ease-in-out;
    border: 3px solid #BAB8D4;
    margin-bottom: 170px;
    position: relative;
    right: 10px;
   
    

   
    
  
} 

.div-2 .box:hover{

    will-change: transform;
    transform :rotateY(0deg) rotateZ(0deg) rotateX(0deg);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    width: 200px;
    height: 276px;
    margin-right: 10px;
    margin-left: 50px;
    scale: 1.18;
    margin-bottom: 30px;
   

  

}

 .div-2 p{

    font-weight: 400;
    color: black;
    line-height: 22px;
    font-size: 14px;
    text-align: center;
 
    
    
} 



@media screen and (min-width:320px) and (max-width:480px ){


    
    .div-2 {

        overflow: hidden;


     
    }

    .div-2 .card-container{

        display: flex;
        justify-content: center;
        


    }
    .div-2 .box{

        
        width: 236px;
        height: 264px;
        transform :rotateY(0deg) rotateZ(0deg) rotateX(0deg);
        transition: none;
        pointer-events: none;
        position: relative;
        right: 0;
        left: 10px;
        
        
    }

  

   

 

    .div-2 p{

        font-family: Open Sans;
        font-size: 14px;
        font-style: italic;
        font-weight: bold;
       
        
     
        
        
    } 

    .div-2 h2{

        font-family: Open Sans;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        text-align: center;
        text-transform: capitalize;
    }

    .div-2 .box img{

        height: 60px;
        width: 60px;
        margin-top: 20px;
      
       
    
    }
    
    
}

@media (max-width: 768px) {
    .div-2 .box {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 50px;
      width: 220px;
      height: 20px;
     
      
    }

    
    .div-2 p{

        font-family: Open Sans;
        font-size: 13px;
        font-style: italic;
        font-weight: bold;
       
        
     
        
        
    } 
  }

  @media (max-width: 992px) {
    .div-2 .box {
      margin-left: 35px;
      margin-right: 30px;
      margin-bottom: 45px;
      width: 230px;
      height: 250px;
    }

    .div-2 p{

        font-family: Open Sans;
        font-size: 13px;
        font-style: italic;
        font-weight: bold;
       
        
     
        
        
    } 
  }


  

.div-3 {

    background-color: rgb(255, 255, 255);
    width: 100%;
    padding-bottom: 50px;
    overflow:hidden ;
    
    

}

.div-3 h1{

    font-size: 40px;
    font-weight: bolder;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
}

.div-3 h5{

    text-align: center;
    font-weight: bolder;
    text-transform: capitalize;
    padding-top: 20px;
}

.div-3 img {

    height: 90px;
    width: 90px;

    
}

.div-3 .infinity-animation{

    display: flex;
    width: calc(250px * 16); 
   animation: scroll 15s linear infinite;
}

.div-3 .slide{
will-change: transform;
width: 250px;
height: 210px;
padding: 20px 48px 28px 48px;
gap: 0px;
border-radius: 10px 0px 0px 0px;
border: 3px solid #FFFFFF;
margin-left: 40px;
transition:  1s;

}

.div-3 .slide:hover{

    scale: 1.2;
    margin-left: 60px;

}

@keyframes scroll {
    0% {
      transform: translateX(0); 
    }
    100% {
      transform: translateX(-50%);
    }
  }


  @media screen and (min-width:320px) and (max-width:768px) {

    .div-3{

        overflow: hidden;
        width: 100%;
    }

    .div-3 img {

        height: 70px;
        width: 70px;
        
    
        
    }

    .div-3 .slide{
        will-change: transform;
        width: 170px;
        height: 150px;
        padding: 20px 48px 28px 48px;
        gap: 0px;
        border-radius: 10px 0px 0px 0px;
        border: 3px solid #FFFFFF;
        margin-left: 20px;
        transition:  5s;
        
        }

        .div-3 h5{

            text-align: center;
            font-weight: bolder;
            text-transform: capitalize;
            font-size: 18px ;
            width: 100%;
        }

        .div-3 h1{

            font-size: 24px;
            text-align: center;
        }

        .div-3 .infinity-animation{

            display: flex;
            width: calc(250px * 16); 
           animation: scroll 20s linear infinite;
        }

        @keyframes scroll {
            0% {
              transform: translateX(0); 
            }
            100% {
              transform: translateX(-50%);
            }
          }
        
    

 
  }

  .div-4{

    background-color: rgb(255, 255, 255);
    height:auto;
    overflow: hidden;
    

}

  .firs{

    position: relative;
    
  

        width: 100%;
       
       ;
          
  
    margin-bottom: 60px;
}
.img-last{
         
    scale:0.65;
     position: absolute;
     left:40px;
     top:-70px;
     
     
     
     


}



.icons{

    scale:0.9;
   margin-left: -10px;
    margin-top: 20px;

}
 .p-last {

    display: flex;
    justify-content: flex-end ;
    align-items: center;
    height:250px;
    font-size: 2.7vw;
    text-transform: capitalize;
    font-weight: 600;
      


   

} 
.last h1{

    text-transform: capitalize;
    margin-bottom: 30px;
    font-size: 2vw;
    margin-top: 50px;
}


.last .last-link{

    text-decoration: none;
    color: var(--text-color);
}

.last ul{

    list-style-type:none;
}

.last li{

    font-size: 1.5vw;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 20px;
    margin-left: -30px;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
}

.div-4 {
    padding: 20px;
    border-radius: 5px;
    margin: 20px;

}

.firs.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
    padding-left: 20px;
}

.img-last img {
    height: 400px;
    width: 450px;
    padding-bottom: 50pX;
    margin-top: 40px;
    object-fit: cover;
    justify-content: flex-start;
    
    
    
}

.p-last p {
    font-size: 32px;
    text-align:center;
    color: #000000;
    margin-bottom: 20px;
}

.last.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.last-d {
    margin: 10px 0;
}


.last-d p{

    font-weight: bolder;
    font-size: 18px;
    
}

.last-d h1 {
    font-size: 32px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.last-d ul {
    list-style: none;
    padding: 0;
}

.last-d ul li {
    margin: 5px 0;
    font-size: 24px
}

.last-link {
    text-decoration: none;
    color: #007bff;
}

.last-link:hover {
    text-decoration: underline;
}

@media screen and (min-width:320px) and (max-width:480px) {

    .firs.row {
        flex-direction: row;
        align-items:first baseline;
        justify-content: automaticall;
    }

    .img-last img {
        height: 250px;
        width: 250px;
        padding-top: 10px;
        padding-right: 80px;
 
        
    }

    .p-last p{
        font-size: 20px;
        text-align:center;
        padding-top: 120px;
        margin-bottom: 10px;
        margin-top: 80px;
        padding-right: 40px;

        
    }
    .last.row {
        flex-direction: row;
    }

    .last-d h1 {
        font-size: 28px;
    }
    .last-d p{
        font-size: 16px;
        justify-content: space-between;
        margin-bottom: 20px;
        font-weight: bolder;
        
    }

    .last-d1 h1{
        font-size: 28px;
    }

    
.last-d ul li{
    font-size: 16px;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bolder
   

    
}


    .last-d1 p{
        font-size: 16px;
      

    }
    

    
    .last-d2 h1{
        font-size: 28px;
    }
    .last-d2 p{
        font-size: 16px;
    }

    

    
}

@media screen and (min-width:480px) and (max-width:768px) {


    
 .div-4 {
        padding: 0px;
        margin-top: -20px;

    }
    .firs.row {
        flex-direction: row;
        align-items:first baseline;
        justify-content: automatically;
    }

    .img-last img {
        height: 300px;
        width: auto;
        display: flex;
        justify-content: center;
        
    }

    .p-last p{
        font-size: 20px;
        text-align: center;
        padding-top: 120px;
        margin-bottom: 10px;
        margin-top: 80px;
        margin-right: 50px;
        margin-left: 10px;
        
    }
    .last.row {
        flex-direction: row;
    }

    .last-d h1 {
        font-size: 28px;
    }
    .last-d p{
        font-size: 16px;
        justify-content: space-between;
        margin-bottom: 20px;
        font-weight: bolder;
    }

    .last-d1 h1{
        font-size: 28px;
    }

    
.last-d ul li{
    font-size: 16px;
    justify-content: space-between;
    margin-bottom: 20px;

    
}


    .last-d1 p{
        font-size: 16px;

    }
    

    
    .last-d2 h1{
        font-size: 28px;
    }
    .last-d2 p{
        font-size: 16px;
    }
    
}

@media screen and (min-width:768px) and (max-width:1200px) {

    .img-last img {
        height: 350px;
        width: 350px;
        margin-left: -90px;
        
        
    }

    .p-last p{
        font-size: 20px;
        text-align: center;
        
        
        
    }
    .last.row {
        flex-direction: row;
    }

    .last-d h1 {
        font-size: 28px;
    }
    .last-d p{
        font-size: 18px;
        justify-content: space-between;
        margin-bottom: 20px;
        font-weight: bolder;
    }

    .last-d1 h1{
        font-size: 28px;
    }

    
.last-d ul li{
    font-size: 18px;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    
}


    .last-d1 p{
        font-size: 18px;

    }
    

    
    .last-d2 h1{
        font-size: 28px;
    }
    .last-d2 p{
        font-size: 16px;
    }


}